import React from "react";
import { Snackbar, styled } from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MuiAlert from '@material-ui/lab/Alert';


interface Props {
  snackbarMsg: string;
  isSnackbarOpen: boolean;
  closeSnackbar: () => void;
  severity: Color;
}

export type Color = 'success' | 'info' | 'warning' | 'error';

interface S {
}

interface SS {
}

const StyledSnackbar = styled(Snackbar)({
  "& .MuiAlert-standardSuccess": {
    backgroundColor: "#fff",
    width: "350px",
    justifyContent: "center",
    bordeRadius: "8px",
    color: "#0F172A",
    fontSize: "16px",
  },

  "& .MuiSnackbar-anchorOriginBottomCenter": {
    bottom: "25px",
  },
});

export default class CustomSnackbar extends BlockComponent<Props, S, SS> {

  render() {

    const { snackbarMsg, isSnackbarOpen, closeSnackbar, severity } = this.props;

    return (
      <StyledSnackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={closeSnackbar}
      >
        
        <MuiAlert elevation={2} severity={severity}>
          {snackbarMsg}
        </MuiAlert>

      </StyledSnackbar>
    );

  }
}

