import React from "react";

// Customizable Area Start
import { Box, Button, Container, Grid, IconButton, InputAdornment, TextField, withStyles } from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import CustomToaster from "../../../components/src/CustomToaster";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// Customizable Area End

export class NewPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPasswordCheckBoxes() {
    const { hasUpperCase, hasSpecialChar, hasNumber, hasMinLength } = this.state;

    return (
      <Box data-test-id="validation-checkbox">
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasUpperCase ? '#34D399': '#94A3B8'}} /> At least one upper case and one lower case letter in password field</div>
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasSpecialChar ? '#34D399': '#94A3B8'}} /> At least one special character</div>
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasNumber ? '#34D399': '#94A3B8'}} /> At least one number</div>
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasMinLength ? '#34D399': '#94A3B8'}} /> Minimum character length is 8 characters</div>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <Box style={webStyles.parentBox} >
          <Container>
            <Grid container spacing={4} >
            <Grid item sm={6} md={6} style={{margin:'0 auto'}} >
                <Box style={{textAlign:'center',marginBottom:'40px'}}>
                  <h1 style={webStyles.titleText}>Reset Password</h1>
                  <p style={webStyles.plaintext}>Enter new password for {this.state.currEmail}</p>
                </Box>

                <Box style={webStyles.labelField}>
                <label style={webStyles.textLabel} htmlFor="password">New Password</label>

              <TextField
              // style={webStyles.textField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><LockOutlinedIcon  style={{color:'#DADADA'}} /></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter new password"
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  data-test-id="txtInputPassword"
                  fullWidth
                  value={this.state.password}
                  name="password"
                  onChange={this.handlePasswordChange}
                  onFocus={this.handleToggleCriteriaVisibility}
                  onBlur={this.handleToggleCriteriaVisibility}
                  error={!!this.state.passwordError}
                  helperText={this.state.passwordError && <span style={webStyles.errorInputText}>{this.state.passwordError}</span>}
                />
              </Box>
              <Box style={webStyles.labelField}>
                <label style={webStyles.textLabel} htmlFor="password">Confirm  Password</label>

              <TextField
              // style={webStyles.textField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><LockOutlinedIcon  style={{color:'#DADADA'}} /></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleToggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Confirm new password"
                  variant="outlined"
                  type={this.state.showConfirmPassword ? 'text' : 'password'}
                  data-test-id="txtInputPsword"
                  fullWidth
                  value={this.state.confirmPassword}
                  name="password"
                  onChange={this.handleConfirmPasswordChange}
                  error={!!this.state.confirmPasswordError}
                  helperText={this.state.confirmPasswordError && <span style={webStyles.errorInputText}>{this.state.confirmPasswordError}</span>}
                />
              </Box>
              <div style={{marginBottom:'40px'}} >
                 {this.renderPasswordCheckBoxes()}
              </div>
              <Box>
                <Button style={webStyles.buttonStyle} disabled={Boolean(this.state.isPasswordInvalid)}  data-test-id="setNewPassword" onClick={this.handleSetNewPass}>Set new password</Button>
              </Box>
              <Box style={{textAlign:'center' as 'center'}} >
                  <p style={webStyles.plaintext} >Back to <span  data-test-id="" onClick={this.handleBacktoSignIn} style={webStyles.anchorTagStyles}>Sign In</span> </p>
              </Box>
            </Grid>
           </Grid>
        </Container>
        <CustomToaster />
       </Box>     
      </>
      // Customizable Area End
    );
  }

}

 // Customizable Area Start
 const webStyles = {
  parentBox:{
    padding: '80px 0'
  },
  backBtn:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    minWidth:'44px',
    borderRadius:'8px'
  },
  anchorTagStyles:{
    color:'#925B5B',
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'5px',
    cursor: "pointer",
    textDecoration: "underline"
  },
  titleText:{
    fontSize:'48px',
    fontWeight:700,
    marginBottom:'10px'
  },
  plaintext:{
    fontSize:'16px',
    fontWeight:400,
    lineHeight:'24px'
  },
  labelField:{
    marginBottom:'30px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap:'10px'
  },
  textLabel:{
    fontSize:'16px',
    lineHeight:'24px',
    fontWeight:700,
  },
  buttonStyle:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    fontSize:'20px',
    fontWeight:700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    padding:'16px 0',
    borderRadius:'8px',
    textTransform:'capitalize' as 'capitalize'
  },
  errorInputText:{
    color:'red',
    fontSize:'14px'
 },
 passwordCriteria:{
  display :'flex',
  gap:'5px',
  alignItems:'center'
},
 }
 export default NewPasswordBlock;
 // Customizable Area End
