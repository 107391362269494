import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Box, Chip, Container, Divider, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import { goToPrevPage } from "../../../components/src/Utilities";
import CustomButton from "../../../components/src/CustomButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { archive, edit, family, privacy } from "./assets";
import CustomCheckbox from "../../../components/src/StyledCheckbox";
import PostCreationController, {Props, configJSON} from "./PostCreationController.web";
import CustomModal from "../../../components/src/CustomModal";
import Loader from "../../../components/src/Loader.web";
import CustomToaster from "../../../components/src/CustomToaster";
import CustomStatusModal from "../../../components/src/CustomStatusModal";




const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});


const StyledBox = styled(Box)({
  "&.disclaimer-container": {
    margin: "35px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 1050px)": {
      flexDirection: "column",
    },
  },

  "&.btn-container": {
    display: "flex",
    justifyContent: "space-between",
    width: "425px",
    "@media (max-width: 1050px)": {
      marginTop: "25px",
    },

    "@media (max-width: 600px)": {
      width: "325px"
    },

  },
});

const StyledFormLabel = styled(FormControlLabel)({
  "& .MuiTypography-body1": {
    "@media (max-width: 960px)": {
      fontSize: "18px",
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
    },
  }

})


const StyledCustomButton = styled(CustomButton)({
  "&.btn-Draft": {
    width: "196px",
    fontSize: "20px",
    height: "56px",
    fontWeight: 700,
    "@media (max-width: 960px)": {
      fontSize: "18px",
      width: "180px",
      height: "50px",
      marginInline: "10px"
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
      width: "160px",
      height: "44px",
    },
  },

  "&.btn-Post": {
    width: "196px",
    fontSize: "20px",
    height: "56px",
    fontWeight: 700,
    "@media (max-width: 960px)": {
      fontSize: "18px",
      width: "180px",
      height: "50px",
      marginInline: "10px"
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
      width: "160px",
      height: "44px",
    },
  }
})



const StyledTypography = styled(Typography)({
  "&.disclaimer": {
    color: "#94A3B8", fontSize: "16px",
    "@media (max-width: 960px)": {
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },

  "&.option-title": {
    display: "flex",
    alignItems: "center",
    marginRight: "25px",
    fontSize: "20px",
    fontWeight: 700,
    color: "#0F172A",
    minWidth: "250px",
    "@media (max-width: 960px)": {
      fontSize: "18px",
      marginRight: "20px",
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
      marginRight: "15px",
      minWidth: "175px",
    },
    "& img": {
      marginRight: "20px",
      "@media (max-width: 960px)": {
        marginRight: "15px"
      },
      "@media (max-width: 500px)": {
        marginRight: "10px"
      },
    },
  }
});


const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    marginRight: "30px",
    padding: "4px 12px 4px 10px",
    width: "230px",
    height: "44px",
    borderRadius: "26px",
    fontSize: "16px",
    color: "#fff",
    fontWeight: 700,
    backgroundColor: "#925B5B",
    fontFamily: "Montserrat",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      width: "230px",
      height: "35px",
      padding: "4px 8px 4px 6px",
      marginRight: "20px",
    },
  },

  "&.MuiChip-outlined": {
    marginRight: "30px",
    padding: "4px 12px 4px 10px",
    width: "230px",
    borderColor: "#925B5B",
    height: "44px",
    borderRadius: "26px",
    fontSize: "16px",
    color: "#925B5B",
    fontWeight: 700,
    backgroundColor: "transparent",
    fontFamily: "Montserrat",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      width: "230px",
      height: "35px",
      padding: "4px 8px 4px 6px",
      marginRight: "20px",
    },
  }

})


const StyledTextfield = styled(TextField)({
  width: "90%",
  "& .MuiOutlinedInput-notchedOutline": {
    fontFamily: "Montserrat !important",
    border: "none"
  },
});




// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <Container
          style={webStyles.containerStyle}
          data-test-id="postcreation-container"
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={webStyles.IconBtnStyle}
              onClick={() => goToPrevPage()}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            <Typography style={webStyles.titleStyle}>
              {configJSON.titlePostCreation}
            </Typography>
          </Box>
          <Box
            style={{
              height: "250px",
              borderRadius: "12px",
              marginTop: "40px",
              width: "100%",
              background: "#F5F1EF",
              position: "relative",
            }}
          >
            <ThemeProvider theme={theme}>
              <StyledTextfield
                data-test-id="title-field"
                variant="outlined"
                placeholder="Add a Title"
                multiline
                minRows={3}
                maxRows={3}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginTop: "-40px" }}
                    >
                      <img
                        src={edit}
                        alt="start-adornment"
                        style={{ width: 24, height: 24 }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) =>
                  this.handleTextFieldChange(event, configJSON.title)
                }
              />
              <Divider
                style={{ backgroundColor: "#CBD5E1", marginBottom: "10px" }}
              />
              <StyledTextfield
                data-test-id="description-field"
                variant="outlined"
                placeholder="What do you want to say?"
                multiline
                minRows={4}
                maxRows={4}
                onChange={(event) =>
                  this.handleTextFieldChange(event, configJSON.description)
                }
              />
            </ThemeProvider>

            <input
              data-test-id="file-input"
              type="file"
              accept="image/*, video/*"
              multiple
              onChange={this.handleFileChange}
              style={{ position: "absolute", bottom: 10, right: 0 }}
            />
          </Box>
          <Box style={{marginTop: "25px"}}>
            {this.state.selectedFiles.map((file) => {
              return (
                <StyledChip
                  onDelete={() => this.handleDeleteFile(file)}
                  data-test-id="file-chip"
                  variant={"outlined"}
                  label={file.name}
                />
              );
            })}
          </Box>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#282627",
              marginTop: "25px",
            }}
          >
            {configJSON.titleSelectCategories}
          </Typography>
          <Box
            style={{
              marginTop: "15px",
              scrollbarWidth: "thin",
              msOverflowStyle: "auto",
              maxWidth: "90%",
              overflowX: "auto",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            {this.state.categoryData.map((category) => {
              return (
                <StyledChip
                  onClick={() =>
                    this.handleCategorySelect(category.attributes.id)
                  }
                  data-test-id="category-chip"
                  icon={
                    <img
                      src={family}
                      height={16}
                      style={{ marginRight: "2px" }}
                    />
                  }
                  variant={
                    this.state.selectedCategoryId === category.attributes.id
                      ? "default"
                      : "outlined"
                  }
                  label={category.attributes.name}
                />
              );
            })}
          </Box>
          <Box style={{ margin: "75px 0px" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "25px",
              }}
            >
              <StyledTypography className="option-title">
                <img src={privacy} alt="" />
                Account Privacy
              </StyledTypography>
              <FormGroup>
                <StyledFormLabel
                 data-test-id="control-label"
                  control={
                    <CustomCheckbox
                      data-test-id="privacy-checkbox"
                      checked={
                        this.state.selectedPrivacyOption === "public_post"
                      }
                      onChange={(event) => this.handleChange(event, "privacy")}
                      name="public_post"
                    />
                  }
                  label="Public"
                />
                <StyledFormLabel
                  data-test-id="control-label"
                  control={
                    <CustomCheckbox
                      data-test-id="privacy-checkbox"
                      checked={
                        this.state.selectedPrivacyOption === "private_post"
                      }
                      onChange={(event) => this.handleChange(event, "privacy")}
                      name="private_post"
                    />
                  }
                  label="Private"
                />
              </FormGroup>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "25px",
              }}
            >
              <StyledTypography className="option-title">
                <img src={archive} alt="" /> Post Archive
              </StyledTypography>
              <FormGroup>
                <StyledFormLabel
                  data-test-id="control-label"
                  control={
                    <CustomCheckbox
                      checked={
                        this.state.selectedArchiveOption === "temporary_default"
                      }
                      onChange={(event) => this.handleChange(event, "archival")}
                      name="temporary_default"
                    />
                  }
                  label="Temporary default"
                />
                <StyledFormLabel
                  data-test-id="control-label"
                  control={
                    <CustomCheckbox
                      checked={this.state.selectedArchiveOption === "archive"}
                      onChange={(event) => this.handleChange(event, "archival")}
                      name="archive"
                    />
                  }
                  label="Archive"
                />
              </FormGroup>
            </Box>
          </Box>
          <Divider
            style={{ backgroundColor: "#E2E8F0", marginBottom: "10px" }}
          />

          <StyledBox className="disclaimer-container">
            <StyledTypography className="disclaimer">
              Disclaimer : Archived posts will be a fee, temporary will be X
              days, etc.
            </StyledTypography>
            <StyledBox className="btn-container">
              <StyledCustomButton
                variant="outlined"
                color="primary"
                fullWidth
                dataTestId="btn-submit"
                btnText={"Save Draft"}
                className="btn-Draft"
                onClick={() => this.createPost("draft")}
              />
              <StyledCustomButton
                variant="contained"
                color="primary"
                fullWidth
                className="btn-Post"
                dataTestId="btn-submit"
                btnText={"Post"}
                onClick={() => this.createPost("post")}
              />
            </StyledBox>
          </StyledBox>
          <CustomModal
            isOpen={this.state.isArchiveModalOpen}
            handleClose={this.toggleArchiveModal}
            successCallback={this.createPost}
          />
          <CustomStatusModal
            isOpen={this.state.isCreationResModalOpen}
            handleClose={this.closeResModal}
          />

          <CustomToaster />
        </Container>
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  subLabelStyle: {
    color: "#64748B",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    margin: "25px 0",
  },
  contactLabelSyle: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  btnSubmitStyle: {
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    borderRadius: "8px",
    height: "56px",
  },
};
// Customizable Area End
