import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  isOpen: boolean;
  handleClose: (toggle: boolean) => void;
  successCallback: (postAction: string) => void
}

const CustomModal = (props: Props) => {
  const { isOpen, handleClose, successCallback } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      fullWidth
      maxWidth="sm"
      style={{borderRadius: "12px"}}
    >
      <DialogTitle
        id="customized-dialog-title"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          {" "}
          Please Confirm!
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          style={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon htmlColor="#334155" />
        </IconButton>
      </DialogTitle>
      <Divider style={{ backgroundColor: "#E2E8F0", margin: "10px 0px" }} />
      <DialogContent>
        <Typography
          style={{
            fontSize: "16px",
            color: "#0F172A",
            display: "flex",
            justifyContent: "center",
            margin: "25px 0 px",
          }}
        >
          Your post will be visible for 24 hours in X price
        </Typography>
      </DialogContent>
      <Divider style={{ backgroundColor: "#E2E8F0", margin: "10px 0px" }} />
      <DialogActions>
        <CustomButton
          variant="contained"
          onClick={() => successCallback("post")}
          color="primary"
          btnText="Confirm"
          style={{
            margin: "10px 15px",
            height: "56px",
            fontSize: "16px"
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
